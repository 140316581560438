import React from "react";
import { isMobileDevice } from "../../helpers";
import Dropdown from "./Dropdown";

const ProjectCategories = ({
  categories,
  handleChange,
  projects,
  category,
}) => {
  if (isMobileDevice(window.screen)) {
    return (
      <div className='category-dropdown-container'>
        <Dropdown
          currentItem={category}
          items={categories}
          handleChange={handleChange}
        />
      </div>
    );
  }

  return (
    <div className='category-button-container'>
      {categories.map((projectCategory) => {
        if (projects[projectCategory].length === 0) {
          return null;
        }
        return (
          <span
            className={`category-button ${
              category === projectCategory ? "highlight" : ""
            }`}
            key={projectCategory}
            onClick={() => handleChange(projectCategory)}
          >
            {projectCategory.toUpperCase()}
          </span>
        );
      })}
    </div>
  );
};

export default ProjectCategories;
