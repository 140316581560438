import React, { Component, Suspense } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import Loader from "react-loader-spinner";
import { isMobile, isTablet } from "react-device-detect"; //doesn't seem to work with iPad IOS
import { withRouter } from "react-router-dom";
import ProjectViewMobile from "./projectViewMobile";
import createCarouselHTML from "./helpers/createCarouselHTML";

import { SLIDE_PAGE_TRANSITION_TIME_MS } from "../../constants";
import { applyFadeOutRedirect, formatProjectsList } from "../../helpers";

function unescape(string) {
  return new DOMParser()
    .parseFromString(string, "text/html")
    .querySelector("html").textContent;
}
class ProjectView extends Component {
  isiPad =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;
  constructor(props) {
    super(props);
    this.state = {
      project: props.selectedProject,
      carousel: createCarouselHTML(props.selectedProject),
      numberOfSlides:
        props.selectedProject.project_slides === undefined
          ? 0
          : props.selectedProject.project_slides.length,
      currentSlide: 0,
      projTitle:
        this.props.selectedProject.title === undefined
          ? "No Project Found"
          : unescape(this.props.selectedProject.title),
      matrix: this.buildPropMatrix(props.selectedProject),
      isLoading:
        Object.keys(props.selectedProject).length === 0 &&
        props.selectedProject.constructor === Object
          ? true
          : false,
      isDirectLink: Object.keys(this.props.selectedProject).length === 0,
      isFirstClick: false,
      orientation: true,
    };
    this.createMarkup = this.createMarkup.bind(this);
    this.buildPropMatrix = this.buildPropMatrix.bind(this);
    this._handleSlideClick = this._handleSlideClick.bind(this);
    this._handleDocumentMouseMove = this._handleDocumentMouseMove.bind(this);
    this._checkKey = this._checkKey.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.updateCurrentSlide = this.updateCurrentSlide.bind(this);
    this.initializeView = this.initializeView.bind(this);
    this.routeChange = this.routeChange.bind(this);
    this.onSwipeLeft = this.onSwipeLeft.bind(this);
    document.onkeydown = this._checkKey;
    this.onOrientationChange = this.onOrientationChange.bind(this);
  }

  routeChange() {
    const onSmallDevice = isMobile || isTablet || this.isiPad;
    const container = onSmallDevice
      ? document.getElementsByClassName("mobile-project-container")[0]
      : document.getElementById("project-container");

    return applyFadeOutRedirect(
      container,
      this.props.history,
      `/projects`,
      onSmallDevice
    );
  }

  initializeView() {
    var that = this;
    var carousel = document.getElementsByClassName("carousel")[0];
    if (carousel !== undefined) {
      carousel.addEventListener(
        "mousemove",
        this._handleDocumentMouseMove,
        false
      );
      //This is triggered when projects are accessed via the projects page
      setTimeout(function () {
        var sliders = document.getElementsByClassName("slider");
        for (var i = 0; i < sliders.length; i++) {
          sliders[i].style.maxHeight = "100%";
        }

        var carouselDots = document.getElementsByClassName("control-dots");
        for (var j = 0; j < carouselDots.length; j++) {
          carouselDots[j].style.opacity = 1;
        }

        var imgContainers = document.getElementsByClassName(
          "slide-img-container"
        );
        for (var k = 0; k < imgContainers.length; k++) {
          imgContainers[k].style.opacity = "1";
        }

        if (that.state.project === undefined) {
          that.props.setHeaderProperties("#000000", false);
          that.props.setMenuButtonColor("#000000");
          that.props.setMenuProperties("#000000");
        } else {
          if (isMobile || isTablet || that.isiPad) {
            that.props.setHeaderProperties("#000000", false);
            that.props.setMenuButtonColor("#000000");
            that.props.setMenuProperties("#000000");
          } else {
            that.props.setHeaderProperties("#FFFFFF", false);
            that.props.setMenuButtonColor("#FFFFFF");
            that.props.setMenuProperties("#000000");
          }
        }
      }, 10);

      setTimeout(function () {
        if (!isMobile && !isTablet && !that.isiPad) {
          var container = document.getElementById("project-detail-container");
          container.style.opacity = "1";
        }
      }, 1000);
    }
  }

  componentDidMount() {
    var that = this;
    that.onOrientationChange();
    if (this.state.carousel === undefined) {
      var project;
      const path = window.location.pathname;
      const proj = path?.substring(path?.lastIndexOf("/") + 1);

      axios
        .get(
          `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.REACT_APP_CONTENTFUL_API}&content_type=project`
        )
        .then((data) => {
          const projects = formatProjectsList(data);
          for (var i = 0; i < projects.length; i++) {
            if (projects[i].slug === proj) {
              project = projects[i];
              break;
            }
          }
          if (project) {
            document.title = "Paul Sangha Creative | " + project.title;

            that.setState({
              project: project,
              carousel: createCarouselHTML(project),
              numberOfSlides: project.image_slides.length,
              currentSlide: 0,
              projTitle: project.title,
              matrix: this.buildPropMatrix(project),
            });

            that.setState({
              isLoading: false,
            });
          } else {
            document.title = "Paul Sangha Creative";
          }

          var carousel = document.getElementsByClassName("carousel")[0];

          if (carousel !== undefined) {
            carousel.addEventListener(
              "mousemove",
              this._handleDocumentMouseMove,
              false
            );
            //This is triggered when projects are accessed via direct link
            setTimeout(function () {
              var sliders = document.getElementsByClassName("slider");
              for (var i = 0; i < sliders.length; i++) {
                sliders[i].style.maxHeight = "100%";
              }

              var carouselDots = document.getElementsByClassName(
                "control-dots"
              );
              for (var j = 0; j < carouselDots.length; j++) {
                carouselDots[j].style.opacity = 1;
              }

              var imgContainers = document.getElementsByClassName(
                "slide-img-container"
              );
              for (var k = 0; k < imgContainers.length; k++) {
                imgContainers[k].style.opacity = "1";
              }

              setTimeout(function () {
                if (that.state.project === undefined) {
                  that.props.setHeaderProperties("#000000", false);
                  that.props.setMenuButtonColor("#000000");
                  that.props.setMenuProperties("#000000");
                } else {
                  if (isMobile || isTablet || that.isiPad) {
                    that.props.setHeaderProperties("#000000", false);
                    that.props.setMenuButtonColor("#000000");
                    that.props.setMenuProperties("#000000");
                  } else {
                    that.props.setHeaderProperties("#FFFFFF", false);
                    that.props.setMenuButtonColor("#FFFFFF");
                    that.props.setMenuProperties("#000000");
                  }
                }
                if (!isMobile && !isTablet && !that.isiPad) {
                  var container = document.getElementById(
                    "project-detail-container"
                  );
                  container.style.opacity = "1";
                  var projContainer = document.getElementById(
                    "project-container"
                  );
                  projContainer.style.opacity = "1";
                }
              }, 200);
            }, 300);
          }
        });
    } else {
      if (this.props.selectedProject.title) {
        document.title =
          "Paul Sangha Creative | " + this.props.selectedProject.title;
      } else {
        document.title = "Paul Sangha Creative";
      }
      that.initializeView();
    }
  }

  checkCursor(e) {
    if (!isMobile && !isTablet && !this.isiPad) {
      var carousel = document.getElementsByClassName("carousel")[0];
      var container = document.getElementById("project-container");
      var slides = document.getElementsByClassName("slide");
      if (e.clientX > container.offsetWidth / 2) {
        carousel.classList.remove("mouse-prev");
        carousel.classList.remove("mouse-next");
        if (this.state.currentSlide === slides.length - 1) {
          carousel.classList.add("mouse-next");
        } else {
          carousel.classList.add("mouse-next");
        }
      } else {
        carousel.classList.remove("mouse-end");
        carousel.classList.remove("mouse-next");
        carousel.classList.add("mouse-prev");
      }
    }
  }

  _handleDocumentMouseMove(e) {
    this.checkCursor(e);
  }

  _checkKey(e) {
    var slides = document.getElementsByClassName("slide");
    let selectedIndex = this.state.currentSlide;

    var key = 0;
    var container = document.getElementById("project-container");
    var that = this;

    //Escape carousel on Desktop
    if (e.key === "Escape") {
      const container = document.getElementById("project-container");

      return applyFadeOutRedirect(
        container,
        this.props.history,
        `/projects`,
        false
      );
    }

    if (e.keyCode === 39) {
      //right
      for (var i = 0; i < slides.length; i++) {
        if (slides[i].classList.contains("selected")) {
          if (i !== slides.length - 1) {
            key = slides[i + 1].children[0].getAttribute("data-key");
          } else {
            key = slides[i].children[0].getAttribute("data-key");
          }
          selectedIndex = i;
        }
      }
      var headerProps = this.state.matrix[key];
      var nextIndex = selectedIndex + 1;

      if (nextIndex === slides.length) {
        //Clicking 'next' on the last slide
        this.routeChange();
      }
      if (nextIndex === 1) {
        if (!isMobile && !isTablet && !this.isiPad) {
          container = document.getElementById("project-detail-container");
          container.style.opacity = "0";
        }
      } else if ((isMobile || isTablet || this.isiPad) && nextIndex === 2) {
        container = document.getElementsByClassName("mobile-project-title")[0];
        container.style.opacity = "0";
      }

      that.props.setHeaderProperties(headerProps[0], headerProps[1]);
      that.props.setMenuButtonColor(headerProps[2]);

      if (!this.state.isFirstClick) {
        selectedIndex = selectedIndex + 1;
      }
      return this.updateCurrentSlide(selectedIndex);
    } else if (e.keyCode === 37) {
      //left
      for (var j = 0; j < slides.length; j++) {
        if (slides[j].classList.contains("selected")) {
          if (j !== 0) {
            key = slides[j - 1].children[0].getAttribute("data-key");
          } else {
            key = slides[j].children[0].getAttribute("data-key");
          }
          selectedIndex = j;
        }
      }
      headerProps = this.state.matrix[key];
      nextIndex = selectedIndex - 1;
      if (nextIndex === 0) {
        if (!isMobile && !isTablet && !this.isiPad) {
          container = document.getElementById("project-detail-container");
          container.style.opacity = "1";
        } else {
          container = document.getElementsByClassName(
            "mobile-project-title"
          )[0];
          container.style.opacity = "1";
        }
      } else if (nextIndex === 1) {
        if (isMobile || isTablet || this.isiPad) {
          container = document.getElementsByClassName(
            "mobile-project-title"
          )[0];
          container.style.opacity = "1";
        }
      }
      that.props.setHeaderProperties(headerProps[0], headerProps[1]);
      that.props.setMenuButtonColor(headerProps[2]);
      if (!this.state.isFirstClick) {
        selectedIndex = selectedIndex - 1;
      }
      return this.updateCurrentSlide(selectedIndex);
    }
  }

  _handleSlideClick(e) {
    e.preventDefault();
    this.checkCursor(e);
    var slides = document.getElementsByClassName("slide");
    let selectedIndex = this.state.currentSlide;
    var key = 0;
    var container = document.getElementById("project-container");
    this.setState((state) => ({
      isFirstClick: true,
    }));
    if (!isMobile && !isTablet && !this.isiPad) {
      if (e.clientX !== 0) {
        if (e.clientX > container.offsetWidth / 2) {
          for (var i = 0; i < slides.length; i++) {
            if (slides[i].classList.contains("selected")) {
              if (i !== slides.length - 1) {
                key = slides[i + 1].children[0].getAttribute("data-key");
              } else {
                key = slides[i].children[0].getAttribute("data-key");
              }
              selectedIndex = i;
            }
          }
          this.updateCurrentSlide(selectedIndex);
          var headerProps = this.state.matrix[key];
          var nextIndex = selectedIndex + 1;
          if (nextIndex === slides.length) {
            //Clicking 'next' on the last slide
            this.routeChange();
          }
          if (nextIndex === 1) {
            if (!isMobile && !isTablet && !this.isiPad) {
              container = document.getElementById("project-detail-container");
              container.style.opacity = "0";
            }
          } else if ((isMobile || isTablet || this.isiPad) && nextIndex === 2) {
            container = document.getElementsByClassName(
              "mobile-project-title"
            )[0];
            container.style.opacity = "0";
          }
          this.next(headerProps);
        } else {
          for (var j = 0; j < slides.length; j++) {
            if (slides[j].classList.contains("selected")) {
              if (j !== 0) {
                key = slides[j - 1].children[0].getAttribute("data-key");
              } else {
                key = slides[j].children[0].getAttribute("data-key");
              }
              selectedIndex = j;
            }
          }
          this.updateCurrentSlide(selectedIndex);
          headerProps = this.state.matrix[key];
          nextIndex = selectedIndex - 1;
          if (nextIndex === 0) {
            if (!isMobile && !isTablet && !this.isiPad) {
              container = document.getElementById("project-detail-container");
              container.style.opacity = "1";
            } else {
              container = document.getElementsByClassName(
                "mobile-project-title"
              )[0];
              container.style.opacity = "1";
            }
          } else if (nextIndex === 1) {
            if (isMobile || isTablet || this.isiPad) {
              container = document.getElementsByClassName(
                "mobile-project-title"
              )[0];
              container.style.opacity = "1";
            }
          }
          this.prev(headerProps);
        }
      }
    }
  }

  next(headerProps) {
    this.setState((state) => ({
      currentSlide: state.currentSlide + 1,
    }));
    var carousel = document.getElementsByClassName("carousel")[0];
    var slides = document.getElementsByClassName("slide");
    carousel.classList.remove("mouse-prev");
    carousel.classList.remove("mouse-next");

    if (this.state.currentSlide + 1 === slides.length - 1) {
      carousel.classList.add("mouse-end");
    } else {
      carousel.classList.add("mouse-next");
    }
    var that = this;
    that.props.setHeaderProperties(headerProps[0], headerProps[1]);
    that.props.setMenuButtonColor(headerProps[2]);
  }

  prev(headerProps) {
    this.setState((state) => ({
      currentSlide: state.currentSlide - 1,
    }));
    var that = this;
    that.props.setHeaderProperties(headerProps[0], headerProps[1]);
    that.props.setMenuButtonColor(headerProps[2]);
  }

  updateCurrentSlide(index) {
    const { currentSlide } = this.state;
    var currentIndex = index;

    if (isMobile || isTablet || this.isiPad) {
      if (currentIndex === 0) {
        var container = document.getElementsByClassName(
          "mobile-project-title"
        )[0];
        container.style.opacity = "1";
      } else if (currentIndex === 1) {
        container = document.getElementsByClassName("mobile-project-title")[0];
        container.style.opacity = "1";
      } else {
        container = document.getElementsByClassName("mobile-project-title")[0];
        container.style.opacity = "0";
      }
    }

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  }

  buildPropMatrix(project) {
    if (Object.keys(project).length === 0 && project.constructor === Object) {
      return;
    }

    var links = project.image_slides;

    var defaultSrc = project.image_slides[0];
    var projectOrder = [];
    try {
      projectOrder = project.project_order.split(";");
    } catch (err) {
      console.log(err);
      for (var i = 0; i < links.length; i++) {
        projectOrder.push("F");
      }
    }

    if (links === undefined) {
      return {};
    } else {
      var slides = [];
      //var index = links.indexOf(defaultSrc);
      //if (index > -1) {
      //links.splice(index, 1);
      //}

      //var fullScreenIndex = 3;
      //var integer = "odd";

      for (var j = 0; j < links.length; j++) {
        var key = j;
        var layout = "";

        /*
        //Second slide has description
        if(key == 1) {
          layout = "desc";
        }
        //Last slide has credits
        else if(key == links.length-1) {
          layout = "cred";
        } 
        //Even slides has smaller image on left
        else if(integer == "even" && key != 0){ 
          layout = "small-right"
          i++;
          integer = "odd";
        } 
        //Odd slides has smaller image on right
        else if(integer == "odd" && key != 0) {
          layout = "small-left"
          i++;
          integer = "even";
        }
        */

        //if it is the first slide, then outptut the intro slide
        if (key === 0 && links[key] !== defaultSrc) {
          layout = "full";
        } else if (key === 0) {
          layout = "full";
        }

        //if it is the second slide, then output the description slide
        else if (key === 1) {
          layout = "desc";
        }

        //if it the the list slide, then output the credits slide
        else if (key === links.length - 1) {
          layout = "cred";
        }

        //if order indicates S, then get the next image also, and the slide should contain S/M
        else if (projectOrder[key] === "L") {
          layout = "small-right";
          j++;
        }

        //if order inficates M, then get the next image also, and the slide should contain M/S
        else if (projectOrder[key] === "S") {
          layout = "small-left";
          j++;
        }

        //if order indicates L, then output a full image in the slide
        else if (projectOrder[key] === "F") {
          layout = "full";
        }

        //Otherwise, default to large
        else {
          layout = "full";
        }
        slides.push([key, layout]);
      }

      var propMatrix = {};
      for (var k = 0; k < slides.length; k++) {
        if (isMobile || isTablet || this.isiPad) {
          propMatrix[slides[k][0]] = ["#000000", false, "#000000"];
        } else {
          if (slides[k][1] === "full") {
            propMatrix[slides[k][0]] = ["#FFFFFF", false, "#FFFFFF"];
          } else if (slides[k][1] === "desc") {
            propMatrix[slides[k][0]] = ["#000000", false, "#FFFFFF"];
          } else if (slides[k][1] === "cred") {
            propMatrix[slides[k][0]] = ["#000000", false, "#FFFFFF"];
          } else if (slides[k][1] === "small-left") {
            propMatrix[slides[k][0]] = ["#000000", false, "#FFFFFF"];
          } else if (slides[k][1] === "small-right") {
            propMatrix[slides[k][0]] = ["#FFFFFF", false, "#000000"];
          }
        }
      }
      return propMatrix;
    }
  }

  onSwipeLeft() {
    var slides = document.getElementsByClassName("slide");
    var selectedIndex = 0;
    if (isMobile || isTablet || this.isiPad) {
      for (var i = 0; i < slides.length; i++) {
        if (slides[i].classList.contains("selected")) {
          selectedIndex = i;
        }
      }
      var nextIndex = selectedIndex + 1;

      if (nextIndex === slides.length) {
        //Clicking 'next' on the last slide
        this.routeChange();
      }
    }
  }

  onOrientationChange() {
    var self = this;
    if ("onorientationchange" in window) {
      window.addEventListener(
        "orientationchange",
        function () {
          self.setState({
            orientation: !self.state.orientation,
          });
        },
        false
      );
    }
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    let imgStyles = {};
    var posStyles = {
      top: this.props.gridAnimationTop + "px",
      left: this.props.gridAnimationLeft + "px",
      opacity: 1,
    };

    if (this.state.isDirectLink) {
      imgStyles.width = "100%";
      imgStyles.height = "100%";
    }

    var mobileTitleStyle = {
      color: "#000000",
    };

    var mobileTitleLineStyle = {
      opacity: "0",
    };

    if (this.state.isLoading) {
      return (
        <div className='loader-container'>
          <Loader type='Oval' color='#000000' height={40} width={40} />
        </div>
      );
    }
    const isMT = isMobile || isTablet || this.isiPad;
    if (isMT) {
      const slides = this.state.project.image_slides;
      return (
        <Suspense
          fallback={
            <div className='loader-container'>
              <Loader type='Oval' color='#000000' height={40} width={40} />
            </div>
          }
        >
          <ProjectViewMobile
            projTitle={this.state.projTitle}
            projDescription={this.state.project?.project_description}
            projCredits={this.state.project?.project_credits}
            projSlides={slides}
            routerBackFunction={this.routeChange}
          />
        </Suspense>
      );
    }

    return (
      <div
        id='project-container'
        className='project-container'
        style={posStyles}
      >
        <div
          id={"project"}
          onClick={this._handleSlideClick}
          className='project fullscreen'
        >
          <Carousel
            selectedItem={this.state.currentSlide}
            useKeyboardArrows={true}
            showStatus={false}
            showIndicators={true}
            showArrows={false}
            showThumbs={false}
            transitionTime={SLIDE_PAGE_TRANSITION_TIME_MS}
          >
            {this.state.carousel}
          </Carousel>
        </div>
        <div id='project-detail-container' className='project-details'>
          <div
            style={isMT ? mobileTitleLineStyle : {}}
            className='project-title-line'
          ></div>
          <div style={isMT ? mobileTitleStyle : {}} className='project-title'>
            <p>{this.state.projTitle}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProjectView);
